import { useState, useEffect } from 'react';
import { doc, onSnapshot, setDoc, collection } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../contexts/AuthContext';
import type { UserSettings } from '../types';

export const useUserSettings = () => {
  const [settings, setSettings] = useState<UserSettings | null>(null);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();

  useEffect(() => {
    if (!user) {
      setSettings(null);
      setLoading(false);
      return;
    }

    const unsubscribe = onSnapshot(
      doc(db, 'userSettings', user.uid),
      (doc) => {
        if (doc.exists()) {
          setSettings({ id: doc.id, ...doc.data() } as UserSettings);
        } else {
          // Create default settings if none exist
          const defaultSettings: Omit<UserSettings, 'id'> = {
            userId: user.uid,
            hourlyRate: 0,
            currency: 'USD',
            subscription: 'basic',
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString()
          };
          setDoc(doc.ref, defaultSettings);
          setSettings({ id: doc.id, ...defaultSettings } as UserSettings);
        }
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [user]);

  const updateSettings = async (data: Partial<UserSettings>) => {
    if (!user) return;

    const settingsRef = doc(db, 'userSettings', user.uid);
    const updateData = {
      ...data,
      updatedAt: new Date().toISOString()
    };

    await setDoc(settingsRef, updateData, { merge: true });
  };

  return {
    settings,
    loading,
    updateSettings
  };
};