import { format } from 'date-fns';

const formatDate = (date: any): string => {
  if (!date) return '';
  try {
    const d = date.toDate ? date.toDate() : new Date(date);
    return format(d, 'yyyy-MM-dd');
  } catch {
    return '';
  }
};

const convertToCSV = (data: any[], headers: string[], fields: string[]): string => {
  const csvRows = [headers.join(',')];
  
  for (const row of data) {
    const values = fields.map(field => {
      const value = row[field];
      // Handle special cases like dates and numbers
      if (field.includes('date') || field.includes('Date')) {
        return formatDate(value);
      }
      if (typeof value === 'string' && value.includes(',')) {
        return `"${value}"`;
      }
      return value ?? '';
    });
    csvRows.push(values.join(','));
  }
  
  return csvRows.join('\n');
};

export const exportClients = (clients: any[]): void => {
  const headers = ['Name', 'Email', 'Phone', 'Address', 'Created Date'];
  const fields = ['name', 'email', 'phone', 'address', 'createdAt'];
  const csv = convertToCSV(clients, headers, fields);
  downloadCSV(csv, 'clients');
};

export const exportMatters = (matters: any[]): void => {
  const headers = ['Title', 'Description', 'Status', 'Billing Type', 'Start Date', 'Due Date'];
  const fields = ['title', 'description', 'status', 'billingType', 'startDate', 'dueDate'];
  const csv = convertToCSV(matters, headers, fields);
  downloadCSV(csv, 'matters');
};

export const exportTimeEntries = (entries: any[]): void => {
  const headers = ['Date', 'Description', 'Hours', 'Rate', 'Amount', 'Matter', 'Billable'];
  const fields = ['date', 'description', 'hours', 'rate', 'amount', 'matterId', 'billable'];
  const csv = convertToCSV(entries, headers, fields);
  downloadCSV(csv, 'time-entries');
};

export const exportDocuments = (documents: any[]): void => {
  const headers = ['Title', 'Description', 'Category', 'Created Date', 'URL'];
  const fields = ['title', 'description', 'category', 'createdAt', 'fileUrl'];
  const csv = convertToCSV(documents, headers, fields);
  downloadCSV(csv, 'documents');
};

const downloadCSV = (csv: string, type: string): void => {
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', `${type}-${format(new Date(), 'yyyy-MM-dd')}.csv`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};