import { getDocumentService } from './documentUtils';

export const openEmailClient = (email: string) => {
  // Check if user has Google Mail open
  const isGmailTab = window.location.href.includes('mail.google.com');
  
  if (isGmailTab) {
    // If already in Gmail, compose new email
    window.location.href = `https://mail.google.com/mail/?view=cm&fs=1&to=${email}`;
  } else {
    // Otherwise open email chooser
    const mailtoLink = `mailto:${email}`;
    window.location.href = mailtoLink;
  }
};

export const getEmailProviderUrl = (email: string, provider: 'google' | 'outlook') => {
  const encodedEmail = encodeURIComponent(email);
  
  switch (provider) {
    case 'google':
      return `https://mail.google.com/mail/?view=cm&fs=1&to=${encodedEmail}`;
    case 'outlook':
      return `https://outlook.office.com/mail/deeplink/compose?to=${encodedEmail}`;
    default:
      return `mailto:${email}`;
  }
};