import React from 'react';
import { format } from 'date-fns';
import { Mail, Phone, MapPin, Calendar, FileText, ExternalLink } from 'lucide-react';
import type { Client } from '../../types';
import EmailButton from './EmailButton';

interface ClientDetailsViewProps {
  client: Client;
  documents: any[];
  onViewDocument: (doc: any) => void;
}

const ClientDetailsView: React.FC<ClientDetailsViewProps> = ({
  client,
  documents,
  onViewDocument
}) => {
  return (
    <div className="space-y-6">
      <div className="grid grid-cols-2 gap-6">
        <div>
          <h3 className="text-sm font-medium text-gray-500 mb-1">Contact Information</h3>
          <div className="space-y-3">
            <div className="flex items-center gap-2">
              <Mail className="w-4 h-4 text-gray-400" />
              <div>
                <p className="text-gray-900">{client.email}</p>
                {client.email && <EmailButton email={client.email} />}
              </div>
            </div>
            <div className="flex items-center gap-2">
              <Phone className="w-4 h-4 text-gray-400" />
              <p className="text-gray-900">{client.phone}</p>
            </div>
            <div className="flex items-start gap-2">
              <MapPin className="w-4 h-4 text-gray-400 mt-1" />
              <p className="text-gray-900 whitespace-pre-line">{client.address}</p>
            </div>
            {client.fileUrl && (
              <div className="flex items-center gap-2">
                <FileText className="w-4 h-4 text-gray-400" />
                <a
                  href={client.fileUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 hover:text-blue-700 flex items-center gap-1"
                >
                  <span>Client File</span>
                  <ExternalLink className="w-4 h-4" />
                </a>
              </div>
            )}
          </div>
        </div>

        <div>
          <h3 className="text-sm font-medium text-gray-500 mb-1">Additional Details</h3>
          <div className="space-y-3">
            <div className="flex items-center gap-2">
              <Calendar className="w-4 h-4 text-gray-400" />
              <p className="text-gray-900">
                Client since: {format(client.createdAt.toDate(), 'MMMM d, yyyy')}
              </p>
            </div>
            <div className="flex items-center gap-2">
              <Calendar className="w-4 h-4 text-gray-400" />
              <p className="text-gray-900">
                Last updated: {format(client.updatedAt.toDate(), 'MMMM d, yyyy')}
              </p>
            </div>
          </div>
        </div>
      </div>

      {client.notes && (
        <div>
          <h3 className="text-sm font-medium text-gray-500 mb-2">Notes</h3>
          <div className="bg-gray-50 p-4 rounded-lg">
            <p className="text-gray-900 whitespace-pre-line">{client.notes}</p>
          </div>
        </div>
      )}

      {documents.length > 0 && (
        <div>
          <h3 className="text-sm font-medium text-gray-500 mb-3">Associated Documents</h3>
          <div className="space-y-2">
            {documents.map(doc => (
              <div key={doc.id} className="flex justify-between items-center p-3 bg-gray-50 rounded-lg">
                <div>
                  <div className="flex items-center gap-2">
                    <FileText className="w-4 h-4 text-gray-400" />
                    <p className="font-medium text-gray-900">{doc.title}</p>
                  </div>
                  {doc.description && (
                    <p className="text-sm text-gray-600 mt-1">{doc.description}</p>
                  )}
                </div>
                <button
                  onClick={() => onViewDocument(doc)}
                  className="text-blue-600 hover:text-blue-700"
                >
                  <ExternalLink className="w-4 h-4" />
                </button>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ClientDetailsView;