import React from 'react';
import { ChevronUp, ChevronDown } from 'lucide-react';
import type { SortConfig } from '../../hooks/useSorting';

interface TableHeaderProps {
  label: string;
  sortKey: string;
  sortConfig: SortConfig;
  onSort: (key: string) => void;
}

const TableHeader: React.FC<TableHeaderProps> = ({
  label,
  sortKey,
  sortConfig,
  onSort,
}) => {
  const isActive = sortConfig.key === sortKey;

  return (
    <th 
      className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer group"
      onClick={() => onSort(sortKey)}
    >
      <div className="flex items-center gap-1">
        <span>{label}</span>
        <div className="flex flex-col">
          {isActive ? (
            sortConfig.direction === 'asc' ? (
              <ChevronUp className="w-4 h-4 text-blue-600" />
            ) : (
              <ChevronDown className="w-4 h-4 text-blue-600" />
            )
          ) : (
            <ChevronDown className="w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-100" />
          )}
        </div>
      </div>
    </th>
  );
};

export default TableHeader;