import React from 'react';
import { format } from 'date-fns';
import { Calendar, Clock, DollarSign, FileText, ExternalLink } from 'lucide-react';
import type { Matter } from '../../types';

interface MatterDetailsViewProps {
  matter: Matter;
  clientName: string;
  documents: any[];
  onViewDocument: (doc: any) => void;
}

const MatterDetailsView: React.FC<MatterDetailsViewProps> = ({
  matter,
  clientName,
  documents,
  onViewDocument
}) => {
  const getStatusColor = (status: Matter['status']) => {
    switch (status) {
      case 'active': return 'bg-green-100 text-green-800';
      case 'pending': return 'bg-yellow-100 text-yellow-800';
      case 'closed': return 'bg-gray-100 text-gray-800';
    }
  };

  const getBillingTypeColor = (type: Matter['billingType']) => {
    switch (type) {
      case 'hourly': return 'bg-blue-100 text-blue-800';
      case 'fixed': return 'bg-purple-100 text-purple-800';
      case 'contingency': return 'bg-orange-100 text-orange-800';
    }
  };

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-2 gap-6">
        <div>
          <h3 className="text-sm font-medium text-gray-500 mb-1">Matter Details</h3>
          <div className="space-y-3">
            <div>
              <p className="text-sm text-gray-500">Status</p>
              <span className={`mt-1 px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusColor(matter.status)}`}>
                {matter.status}
              </span>
            </div>
            <div>
              <p className="text-sm text-gray-500">Billing Type</p>
              <span className={`mt-1 px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getBillingTypeColor(matter.billingType)}`}>
                {matter.billingType}
              </span>
            </div>
            <div>
              <p className="text-sm text-gray-500">Client</p>
              <p className="text-gray-900">{clientName}</p>
            </div>
          </div>
        </div>

        <div>
          <h3 className="text-sm font-medium text-gray-500 mb-1">Dates</h3>
          <div className="space-y-3">
            <div className="flex items-center gap-2">
              <Calendar className="w-4 h-4 text-gray-400" />
              <div>
                <p className="text-sm text-gray-500">Start Date</p>
                <p className="text-gray-900">{format(new Date(matter.startDate), 'MMMM d, yyyy')}</p>
              </div>
            </div>
            {matter.dueDate && (
              <div className="flex items-center gap-2">
                <Clock className="w-4 h-4 text-gray-400" />
                <div>
                  <p className="text-sm text-gray-500">Due Date</p>
                  <p className="text-gray-900">{format(new Date(matter.dueDate), 'MMMM d, yyyy')}</p>
                </div>
              </div>
            )}
            <div className="flex items-center gap-2">
              <Calendar className="w-4 h-4 text-gray-400" />
              <div>
                <p className="text-sm text-gray-500">Created</p>
                <p className="text-gray-900">{format(matter.createdAt.toDate(), 'MMMM d, yyyy')}</p>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <Calendar className="w-4 h-4 text-gray-400" />
              <div>
                <p className="text-sm text-gray-500">Last Updated</p>
                <p className="text-gray-900">{format(matter.updatedAt.toDate(), 'MMMM d, yyyy')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <h3 className="text-sm font-medium text-gray-500 mb-2">Description</h3>
        <div className="bg-gray-50 p-4 rounded-lg">
          <p className="text-gray-900 whitespace-pre-line">{matter.description}</p>
        </div>
      </div>

      {matter.notes && (
        <div>
          <h3 className="text-sm font-medium text-gray-500 mb-2">Notes</h3>
          <div className="bg-gray-50 p-4 rounded-lg">
            <p className="text-gray-900 whitespace-pre-line">{matter.notes}</p>
          </div>
        </div>
      )}

      {documents.length > 0 && (
        <div>
          <h3 className="text-sm font-medium text-gray-500 mb-3">Associated Documents</h3>
          <div className="space-y-2">
            {documents.map(doc => (
              <div key={doc.id} className="flex justify-between items-center p-3 bg-gray-50 rounded-lg">
                <div>
                  <div className="flex items-center gap-2">
                    <FileText className="w-4 h-4 text-gray-400" />
                    <p className="font-medium text-gray-900">{doc.title}</p>
                  </div>
                  {doc.description && (
                    <p className="text-sm text-gray-600 mt-1">{doc.description}</p>
                  )}
                </div>
                <button
                  onClick={() => onViewDocument(doc)}
                  className="text-blue-600 hover:text-blue-700"
                >
                  <ExternalLink className="w-4 h-4" />
                </button>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default MatterDetailsView;