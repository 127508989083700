import React, { useState } from 'react';
import { Download, Upload, AlertCircle } from 'lucide-react';
import { useClients } from '../../hooks/useClients';
import { useMatters } from '../../hooks/useMatters';
import { downloadTemplate, parseImportFile } from '../../utils/importExport';
import DataExportSection from './DataExportSection';
import toast from 'react-hot-toast';

const ImportExportSettings = () => {
  const { addClient } = useClients();
  const { addMatter } = useMatters();
  const [importing, setImporting] = useState(false);

  const handleDownloadTemplate = () => {
    downloadTemplate();
    toast.success('Template downloaded successfully');
  };

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    if (!file.name.endsWith('.csv')) {
      toast.error('Please upload a CSV file');
      return;
    }

    setImporting(true);
    try {
      const { clients, matters } = await parseImportFile(file);
      
      // Import clients first
      for (const client of clients) {
        await addClient(client);
      }

      // Then import matters with client references
      for (const matter of matters) {
        await addMatter(matter);
      }

      toast.success('Data imported successfully');
      e.target.value = ''; // Reset file input
    } catch (error) {
      toast.error('Failed to import data. Please check the file format.');
      console.error('Import error:', error);
    } finally {
      setImporting(false);
    }
  };

  return (
    <div className="bg-white rounded-xl shadow-sm p-6">
      <h2 className="text-xl font-semibold mb-6">Data Management</h2>

      <div className="space-y-6">
        <DataExportSection />

        <div className="border-t pt-6">
          <h2 className="text-lg font-semibold mb-4">Import Data</h2>
          
          <div className="bg-blue-50 border border-blue-200 rounded-lg p-4">
            <div className="flex gap-3">
              <AlertCircle className="w-5 h-5 text-blue-600 shrink-0" />
              <div className="text-sm text-blue-700">
                <p className="font-medium mb-1">Import Instructions:</p>
                <ol className="list-decimal ml-4 space-y-1">
                  <li>Download the template CSV file</li>
                  <li>Fill in your client and matter information</li>
                  <li>Upload the completed file to import your data</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-6 mt-6">
            <div className="p-4 border rounded-lg">
              <h3 className="font-medium mb-2">Download Template</h3>
              <p className="text-sm text-gray-600 mb-4">
                Get the CSV template file to format your data for import
              </p>
              <button
                onClick={handleDownloadTemplate}
                className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 flex items-center gap-2"
              >
                <Download className="w-4 h-4" />
                Download Template
              </button>
            </div>

            <div className="p-4 border rounded-lg">
              <h3 className="font-medium mb-2">Import Data</h3>
              <p className="text-sm text-gray-600 mb-4">
                Upload your completed CSV file to import clients and matters
              </p>
              <label className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 flex items-center gap-2 cursor-pointer">
                <Upload className="w-4 h-4" />
                {importing ? 'Importing...' : 'Upload CSV'}
                <input
                  type="file"
                  accept=".csv"
                  className="hidden"
                  onChange={handleFileUpload}
                  disabled={importing}
                />
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImportExportSettings;