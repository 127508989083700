import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useUserSettings } from '../../hooks/useUserSettings';
import toast from 'react-hot-toast';

const ProfileSettings = () => {
  const { user } = useAuth();
  const { settings, updateSettings } = useUserSettings();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    initials: '',
    email: user?.email || '',
    phone: '',
    company: '',
    address: ''
  });

  useEffect(() => {
    if (settings) {
      setFormData(prev => ({
        ...prev,
        firstName: settings.firstName || '',
        lastName: settings.lastName || '',
        initials: settings.initials || '',
        phone: settings.phone || '',
        company: settings.company || '',
        address: settings.address || ''
      }));
    }
  }, [settings, user]);

  const generateInitials = (first: string, last: string) => {
    const firstInitial = first.charAt(0).toUpperCase();
    const lastInitial = last.charAt(0).toUpperCase();
    return `${firstInitial}${lastInitial}`;
  };

  const handleNameChange = (field: 'firstName' | 'lastName', value: string) => {
    setFormData(prev => {
      const updates = { ...prev, [field]: value };
      // Auto-generate initials when both names are present
      if (updates.firstName && updates.lastName) {
        updates.initials = generateInitials(updates.firstName, updates.lastName);
      }
      return updates;
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await updateSettings({
        firstName: formData.firstName,
        lastName: formData.lastName,
        initials: formData.initials,
        phone: formData.phone,
        company: formData.company,
        address: formData.address,
        displayName: `${formData.firstName} ${formData.lastName}`.trim()
      });
      toast.success('Profile updated successfully');
    } catch (error) {
      toast.error('Failed to update profile');
    }
  };

  return (
    <div className="bg-white rounded-xl shadow-sm p-6">
      <h2 className="text-xl font-semibold mb-6">Profile Settings</h2>
      <form onSubmit={handleSubmit} className="space-y-6 max-w-2xl">
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              First Name
            </label>
            <input
              type="text"
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500"
              value={formData.firstName}
              onChange={(e) => handleNameChange('firstName', e.target.value)}
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Last Name
            </label>
            <input
              type="text"
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500"
              value={formData.lastName}
              onChange={(e) => handleNameChange('lastName', e.target.value)}
            />
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Initials
          </label>
          <input
            type="text"
            maxLength={3}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500"
            value={formData.initials}
            onChange={(e) => setFormData(prev => ({ ...prev, initials: e.target.value.toUpperCase() }))}
            placeholder="Automatically generated from name"
          />
          <p className="mt-1 text-sm text-gray-500">
            These initials will appear on your invoices and documents
          </p>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Email Address
          </label>
          <input
            type="email"
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500 bg-gray-50"
            value={formData.email}
            disabled
          />
        </div>

        <div className="border-t pt-6">
          <h3 className="text-lg font-medium mb-4">Company Information</h3>
          <p className="text-sm text-gray-500 mb-4">
            This information will appear on your invoices.
          </p>

          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Company Name
              </label>
              <input
                type="text"
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500"
                value={formData.company}
                onChange={(e) => setFormData(prev => ({ ...prev, company: e.target.value }))}
                placeholder="Your Company Name"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Phone Number
              </label>
              <input
                type="tel"
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500"
                value={formData.phone}
                onChange={(e) => setFormData(prev => ({ ...prev, phone: e.target.value }))}
                placeholder="Your Phone Number"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Business Address
              </label>
              <textarea
                rows={3}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500"
                value={formData.address}
                onChange={(e) => setFormData(prev => ({ ...prev, address: e.target.value }))}
                placeholder="Your Complete Business Address"
              />
            </div>
          </div>
        </div>

        <div>
          <button
            type="submit"
            className="px-4 py-2 text-white bg-blue-600 rounded-lg hover:bg-blue-700"
          >
            Save Changes
          </button>
        </div>
      </form>
    </div>
  );
};

export default ProfileSettings;