import React, { useState, useEffect } from 'react';
import { X, Upload, AlertCircle } from 'lucide-react';
import { useStorage } from '../../hooks/useStorage';
import { useDocumentTemplates } from '../../hooks/useDocumentTemplates';
import { useClients } from '../../hooks/useClients';
import { useMatters } from '../../hooks/useMatters';
import toast from 'react-hot-toast';

interface DocumentUploadProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  editDocument?: any;
}

const DocumentUpload: React.FC<DocumentUploadProps> = ({ 
  isOpen, 
  onClose, 
  onSuccess,
  editDocument 
}) => {
  const { uploadFile } = useStorage();
  const { addTemplate, updateTemplate } = useDocumentTemplates();
  const { clients } = useClients();
  const { matters } = useMatters();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [formData, setFormData] = useState({
    title: editDocument?.title || '',
    description: editDocument?.description || '',
    category: editDocument?.category || '',
    clientId: editDocument?.clientId || '',
    matterId: editDocument?.matterId || '',
    fileUrl: editDocument?.fileUrl || ''
  });

  const validateFileUrl = (url: string) => {
    const googleDocsPattern = /^https:\/\/(docs|drive)\.google\.com/;
    const microsoftPattern = /^https:\/\/(onedrive\.live\.com|.*\.sharepoint\.com)/;
    return googleDocsPattern.test(url) || microsoftPattern.test(url);
  };

  const filteredMatters = matters.filter(
    matter => !formData.clientId || matter.clientId === formData.clientId
  );

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!formData.fileUrl && !selectedFile && !editDocument) {
      toast.error('Please provide either a file or a document URL');
      return;
    }

    if (formData.fileUrl && !validateFileUrl(formData.fileUrl)) {
      toast.error('Please provide a valid Google Docs or Microsoft OneDrive URL');
      return;
    }

    try {
      let fileUrl = formData.fileUrl;
      
      if (selectedFile) {
        fileUrl = await uploadFile(selectedFile, `documents/${selectedFile.name}`);
      }

      const documentData = {
        ...formData,
        fileUrl: fileUrl || editDocument?.fileUrl,
        content: '',
        category: formData.category || 'External Link'
      };

      if (editDocument) {
        await updateTemplate(editDocument.id, documentData);
        toast.success('Document updated successfully');
      } else {
        await addTemplate(documentData);
        toast.success('Document added successfully');
      }

      onSuccess();
      onClose();
    } catch (error) {
      toast.error(editDocument ? 'Failed to update document' : 'Failed to add document');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-xl shadow-lg w-full max-w-2xl max-h-[90vh] flex flex-col">
        <div className="flex justify-between items-center p-6 border-b shrink-0">
          <h2 className="text-xl font-semibold">
            {editDocument ? 'Edit Document' : 'Add Document'}
          </h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X className="w-5 h-5" />
          </button>
        </div>

        <div className="overflow-y-auto flex-1 p-6">
          <form onSubmit={handleSubmit} className="space-y-6">
            {!editDocument && (
              <>
                <div className="bg-blue-50 border border-blue-200 rounded-lg p-4">
                  <div className="flex gap-3">
                    <AlertCircle className="w-5 h-5 text-blue-600 shrink-0" />
                    <p className="text-sm text-blue-700">
                      Please provide either a Google Docs/Drive or Microsoft OneDrive URL, or upload a file directly.
                    </p>
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Document URL (Optional)
                  </label>
                  <input
                    type="url"
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500"
                    value={formData.fileUrl}
                    onChange={(e) => setFormData(prev => ({ ...prev, fileUrl: e.target.value }))}
                    placeholder="https://docs.google.com/... or https://onedrive.live.com/..."
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Or Upload File
                  </label>
                  <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-lg">
                    <div className="space-y-1 text-center">
                      <Upload className="mx-auto h-12 w-12 text-gray-400" />
                      <div className="flex text-sm text-gray-600">
                        <label className="relative cursor-pointer rounded-md font-medium text-blue-600 hover:text-blue-500">
                          <span>Upload a file</span>
                          <input
                            type="file"
                            className="sr-only"
                            onChange={(e) => {
                              const file = e.target.files?.[0];
                              if (file) {
                                setSelectedFile(file);
                                setFormData(prev => ({ ...prev, title: file.name.split('.')[0] }));
                              }
                            }}
                            accept=".pdf,.doc,.docx,.txt"
                          />
                        </label>
                      </div>
                      <p className="text-xs text-gray-500">PDF, DOC, DOCX up to 10MB</p>
                      {selectedFile && (
                        <p className="text-sm text-gray-600">Selected: {selectedFile.name}</p>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Title
              </label>
              <input
                type="text"
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500"
                value={formData.title}
                onChange={(e) => setFormData(prev => ({ ...prev, title: e.target.value }))}
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Description
              </label>
              <textarea
                rows={3}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500"
                value={formData.description}
                onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
              />
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Client
                </label>
                <select
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500"
                  value={formData.clientId}
                  onChange={(e) => setFormData(prev => ({ 
                    ...prev, 
                    clientId: e.target.value,
                    matterId: '' // Reset matter when client changes
                  }))}
                >
                  <option value="">Select Client</option>
                  {clients.map(client => (
                    <option key={client.id} value={client.id}>{client.name}</option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Matter
                </label>
                <select
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500"
                  value={formData.matterId}
                  onChange={(e) => setFormData(prev => ({ ...prev, matterId: e.target.value }))}
                  disabled={!formData.clientId}
                >
                  <option value="">Select Matter</option>
                  {filteredMatters.map(matter => (
                    <option key={matter.id} value={matter.id}>{matter.title}</option>
                  ))}
                </select>
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Category
              </label>
              <select
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500"
                value={formData.category}
                onChange={(e) => setFormData(prev => ({ ...prev, category: e.target.value }))}
              >
                <option value="">Select Category</option>
                <option value="Contracts">Contracts</option>
                <option value="Court Documents">Court Documents</option>
                <option value="Client Communications">Client Communications</option>
                <option value="Internal Documents">Internal Documents</option>
                <option value="Other">Other</option>
              </select>
            </div>
          </form>
        </div>

        <div className="flex justify-end gap-3 p-6 border-t shrink-0">
          <button
            type="button"
            onClick={onClose}
            className="px-4 py-2 text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className="px-4 py-2 text-white bg-blue-600 rounded-lg hover:bg-blue-700"
          >
            {editDocument ? 'Update Document' : 'Add Document'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DocumentUpload;