import React, { useState } from 'react';
import { X } from 'lucide-react';
import { useNotes } from '../../../hooks/useNotes';
import { useClients } from '../../../hooks/useClients';
import { useMatters } from '../../../hooks/useMatters';
import toast from 'react-hot-toast';

interface NoteFormProps {
  isOpen: boolean;
  onClose: () => void;
}

const NoteForm: React.FC<NoteFormProps> = ({ isOpen, onClose }) => {
  const { addNote } = useNotes();
  const { clients } = useClients();
  const { matters } = useMatters();
  const [formData, setFormData] = useState({
    title: '',
    content: '',
    clientId: '',
    matterId: '',
    type: 'note' as 'note' | 'task',
    completed: false,
    dueDate: ''
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await addNote(formData);
      toast.success('Note created successfully');
      onClose();
    } catch (error) {
      toast.error('Failed to create note');
    }
  };

  if (!isOpen) return null;

  const filteredMatters = matters.filter(
    matter => !formData.clientId || matter.clientId === formData.clientId
  );

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl shadow-lg w-full max-w-lg">
        <div className="flex justify-between items-center p-6 border-b">
          <h2 className="text-xl font-semibold">New Note</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X className="w-5 h-5" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-6 space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Type
            </label>
            <select
              className="w-full px-3 py-2 border border-gray-300 rounded-lg"
              value={formData.type}
              onChange={(e) => setFormData(prev => ({ ...prev, type: e.target.value as 'note' | 'task' }))}
            >
              <option value="note">Note</option>
              <option value="task">Task</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Title
            </label>
            <input
              type="text"
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-lg"
              value={formData.title}
              onChange={(e) => setFormData(prev => ({ ...prev, title: e.target.value }))}
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Content
            </label>
            <textarea
              required
              rows={4}
              className="w-full px-3 py-2 border border-gray-300 rounded-lg"
              value={formData.content}
              onChange={(e) => setFormData(prev => ({ ...prev, content: e.target.value }))}
            />
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Client (Optional)
              </label>
              <select
                className="w-full px-3 py-2 border border-gray-300 rounded-lg"
                value={formData.clientId}
                onChange={(e) => setFormData(prev => ({ 
                  ...prev, 
                  clientId: e.target.value,
                  matterId: '' // Reset matter when client changes
                }))}
              >
                <option value="">Select Client</option>
                {clients.map(client => (
                  <option key={client.id} value={client.id}>{client.name}</option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Matter (Optional)
              </label>
              <select
                className="w-full px-3 py-2 border border-gray-300 rounded-lg"
                value={formData.matterId}
                onChange={(e) => setFormData(prev => ({ ...prev, matterId: e.target.value }))}
                disabled={!formData.clientId}
              >
                <option value="">Select Matter</option>
                {filteredMatters.map(matter => (
                  <option key={matter.id} value={matter.id}>{matter.title}</option>
                ))}
              </select>
            </div>
          </div>

          {formData.type === 'task' && (
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Due Date
              </label>
              <input
                type="date"
                className="w-full px-3 py-2 border border-gray-300 rounded-lg"
                value={formData.dueDate}
                onChange={(e) => setFormData(prev => ({ ...prev, dueDate: e.target.value }))}
              />
            </div>
          )}

          <div className="flex justify-end gap-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 text-white bg-blue-600 rounded-lg hover:bg-blue-700"
            >
              Create Note
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NoteForm;