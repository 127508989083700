import React, { useState } from 'react';
import { format, isWithinInterval, parseISO } from 'date-fns';
import { Edit2, Trash2, Receipt, DollarSign, ArrowLeft } from 'lucide-react';
import { useTimeEntries } from '../../hooks/useTimeEntries';
import { useMatters } from '../../hooks/useMatters';
import { useClients } from '../../hooks/useClients';
import { useSorting } from '../../hooks/useSorting';
import { useFilters } from '../../hooks/useFilters';
import TableHeader from './TableHeader';
import TimeEntryFilters from './TimeEntryFilters';
import type { TimeEntry } from '../../types';
import toast from 'react-hot-toast';

interface TimeEntriesTableProps {
  onEdit: (entry: TimeEntry) => void;
}

const TimeEntriesTable: React.FC<TimeEntriesTableProps> = ({ onEdit }) => {
  const { timeEntries, loading, deleteTimeEntry, updateTimeEntry } = useTimeEntries();
  const { matters } = useMatters();
  const { clients } = useClients();
  const [activeTab, setActiveTab] = useState<'unbilled' | 'billed' | 'paid'>('unbilled');
  const { sortConfig, toggleSort } = useSorting('date');
  const { filters, updateFilter, resetFilters } = useFilters({
    clientId: '',
    matterId: '',
    dateFrom: '',
    dateTo: ''
  });

  const handleDelete = async (id: string) => {
    try {
      await deleteTimeEntry(id);
      toast.success('Time entry deleted successfully');
    } catch (error) {
      toast.error('Failed to delete time entry');
    }
  };

  const handleMarkAsBilled = async (entry: TimeEntry) => {
    try {
      await updateTimeEntry(entry.id, { billed: true });
      toast.success('Entry marked as billed');
    } catch (error) {
      toast.error('Failed to mark entry as billed');
    }
  };

  const handleMarkAsPaid = async (entry: TimeEntry) => {
    try {
      await updateTimeEntry(entry.id, { paid: true });
      toast.success('Entry marked as paid');
    } catch (error) {
      toast.error('Failed to mark entry as paid');
    }
  };

  const handleRevertToBilled = async (entry: TimeEntry) => {
    try {
      await updateTimeEntry(entry.id, { paid: false });
      toast.success('Entry reverted to billed status');
    } catch (error) {
      toast.error('Failed to revert entry status');
    }
  };

  const getMatterTitle = (matterId: string) => {
    return matters.find(m => m.id === matterId)?.title || 'Unknown Matter';
  };

  const getClientName = (clientId: string) => {
    return clients.find(c => c.id === clientId)?.name || 'Unknown Client';
  };

  const getFilteredAndSortedEntries = (entries: TimeEntry[]) => {
    // First apply status filter
    let filteredEntries = entries.filter(entry => {
      if (activeTab === 'unbilled') return !entry.billed;
      if (activeTab === 'billed') return entry.billed && !entry.paid;
      return entry.paid;
    });

    // Then apply user filters
    filteredEntries = filteredEntries.filter(entry => {
      const matchesClient = !filters.clientId || entry.clientId === filters.clientId;
      const matchesMatter = !filters.matterId || entry.matterId === filters.matterId;
      
      let matchesDate = true;
      if (filters.dateFrom || filters.dateTo) {
        const entryDate = parseISO(entry.date);
        const start = filters.dateFrom ? parseISO(filters.dateFrom) : new Date(0);
        const end = filters.dateTo ? parseISO(filters.dateTo) : new Date();
        matchesDate = isWithinInterval(entryDate, { start, end });
      }

      return matchesClient && matchesMatter && matchesDate;
    });

    // Finally apply sorting
    return [...filteredEntries].sort((a, b) => {
      const direction = sortConfig.direction === 'asc' ? 1 : -1;
      
      switch (sortConfig.key) {
        case 'date':
          return (new Date(a.date).getTime() - new Date(b.date).getTime()) * direction;
        case 'matter':
          return getMatterTitle(a.matterId).localeCompare(getMatterTitle(b.matterId)) * direction;
        case 'client':
          return getClientName(a.clientId).localeCompare(getClientName(b.clientId)) * direction;
        case 'description':
          return a.description.localeCompare(b.description) * direction;
        case 'hours':
          const aHours = a.billingType === 'fixed' ? 0 : (a.hours || 0);
          const bHours = b.billingType === 'fixed' ? 0 : (b.hours || 0);
          return (aHours - bHours) * direction;
        case 'rate':
          const aRate = a.billingType === 'fixed' ? 0 : (a.rate || 0);
          const bRate = b.billingType === 'fixed' ? 0 : (b.rate || 0);
          return (aRate - bRate) * direction;
        case 'amount':
          const aAmount = a.billingType === 'fixed' 
            ? (a.fixedFeeAmount || 0)
            : ((a.hours || 0) * (a.rate || 0));
          const bAmount = b.billingType === 'fixed'
            ? (b.fixedFeeAmount || 0)
            : ((b.hours || 0) * (b.rate || 0));
          return (aAmount - bAmount) * direction;
        default:
          return 0;
      }
    });
  };

  const filteredAndSortedEntries = getFilteredAndSortedEntries(timeEntries);

  return (
    <div>
      <div className="border-b mb-4">
        <div className="flex gap-4 px-4">
          <button
            onClick={() => setActiveTab('unbilled')}
            className={`py-2 px-4 border-b-2 ${
              activeTab === 'unbilled'
                ? 'border-blue-600 text-blue-600'
                : 'border-transparent text-gray-500 hover:text-gray-700'
            }`}
          >
            Unbilled Entries
          </button>
          <button
            onClick={() => setActiveTab('billed')}
            className={`py-2 px-4 border-b-2 ${
              activeTab === 'billed'
                ? 'border-blue-600 text-blue-600'
                : 'border-transparent text-gray-500 hover:text-gray-700'
            }`}
          >
            Billed Entries
          </button>
          <button
            onClick={() => setActiveTab('paid')}
            className={`py-2 px-4 border-b-2 ${
              activeTab === 'paid'
                ? 'border-blue-600 text-blue-600'
                : 'border-transparent text-gray-500 hover:text-gray-700'
            }`}
          >
            Paid Entries
          </button>
        </div>
      </div>

      <TimeEntryFilters
        filters={filters}
        onFilterChange={updateFilter}
        onReset={resetFilters}
        clients={clients}
        matters={matters}
      />

      <div className="overflow-x-auto">
        <table className="w-full">
          <thead>
            <tr className="bg-gray-50">
              <TableHeader
                label="Date"
                sortKey="date"
                sortConfig={sortConfig}
                onSort={toggleSort}
              />
              <TableHeader
                label="Matter"
                sortKey="matter"
                sortConfig={sortConfig}
                onSort={toggleSort}
              />
              <TableHeader
                label="Client"
                sortKey="client"
                sortConfig={sortConfig}
                onSort={toggleSort}
              />
              <TableHeader
                label="Description"
                sortKey="description"
                sortConfig={sortConfig}
                onSort={toggleSort}
              />
              <TableHeader
                label="Hours/Fee"
                sortKey="hours"
                sortConfig={sortConfig}
                onSort={toggleSort}
              />
              <TableHeader
                label="Rate"
                sortKey="rate"
                sortConfig={sortConfig}
                onSort={toggleSort}
              />
              <TableHeader
                label="Amount"
                sortKey="amount"
                sortConfig={sortConfig}
                onSort={toggleSort}
              />
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {loading ? (
              <tr>
                <td colSpan={8} className="px-6 py-8 text-center text-gray-500">
                  Loading time entries...
                </td>
              </tr>
            ) : filteredAndSortedEntries.length === 0 ? (
              <tr>
                <td colSpan={8} className="px-6 py-8 text-center text-gray-500">
                  No entries found
                </td>
              </tr>
            ) : (
              filteredAndSortedEntries.map((entry) => (
                <tr key={entry.id} className="hover:bg-gray-50">
                  <td className="px-4 py-3 text-sm">
                    {format(new Date(entry.date), 'MMM d, yyyy')}
                  </td>
                  <td className="px-4 py-3 text-sm">{getMatterTitle(entry.matterId)}</td>
                  <td className="px-4 py-3 text-sm">{getClientName(entry.clientId)}</td>
                  <td className="px-4 py-3 text-sm">{entry.description}</td>
                  <td className="px-4 py-3 text-sm">
                    {entry.billingType === 'fixed' ? 
                      entry.fixedFeeTitle : 
                      `${entry.hours} hrs`}
                  </td>
                  <td className="px-4 py-3 text-sm">
                    {entry.billingType === 'fixed' ? 
                      '-' : 
                      `$${entry.rate}/hr`}
                  </td>
                  <td className="px-4 py-3 text-sm">
                    ${entry.billingType === 'fixed' ? 
                      entry.fixedFeeAmount?.toFixed(2) : 
                      (entry.hours * entry.rate).toFixed(2)}
                  </td>
                  <td className="px-4 py-3">
                    <div className="flex gap-2">
                      {!entry.billed && (
                        <button
                          onClick={() => handleMarkAsBilled(entry)}
                          className="text-green-600 hover:text-green-700"
                          title="Mark as Billed"
                        >
                          <Receipt className="w-4 h-4" />
                        </button>
                      )}
                      {entry.billed && !entry.paid && (
                        <button
                          onClick={() => handleMarkAsPaid(entry)}
                          className="text-green-600 hover:text-green-700"
                          title="Mark as Paid"
                        >
                          <DollarSign className="w-4 h-4" />
                        </button>
                      )}
                      {entry.paid ? (
                        <button
                          onClick={() => handleRevertToBilled(entry)}
                          className="text-orange-600 hover:text-orange-700"
                          title="Revert to Billed"
                        >
                          <ArrowLeft className="w-4 h-4" />
                        </button>
                      ) : (
                        <>
                          <button
                            onClick={() => onEdit(entry)}
                            className="text-blue-600 hover:text-blue-700"
                          >
                            <Edit2 className="w-4 h-4" />
                          </button>
                          <button
                            onClick={() => handleDelete(entry.id)}
                            className="text-red-600 hover:text-red-700"
                          >
                            <Trash2 className="w-4 h-4" />
                          </button>
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TimeEntriesTable;