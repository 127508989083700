import { format } from 'date-fns';

const TEMPLATE_HEADERS = [
  'Client Name',
  'Client Email',
  'Client Phone',
  'Client Address',
  'Matter Title',
  'Matter Description',
  'Matter Status',
  'Matter Billing Type',
  'Matter Start Date',
  'Matter Due Date'
];

export const downloadTemplate = () => {
  // Create CSV content
  const csvContent = [
    TEMPLATE_HEADERS.join(','),
    'John Doe,john@example.com,123-456-7890,123 Main St,Contract Review,Review of service agreement,active,hourly,2024-01-01,2024-02-01'
  ].join('\n');

  // Create blob and download
  const blob = new Blob([csvContent], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `client-matter-template-${format(new Date(), 'yyyy-MM-dd')}.csv`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};

export const parseImportFile = (file: File): Promise<{
  clients: any[];
  matters: any[];
}> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    
    reader.onload = (event) => {
      try {
        const text = event.target?.result as string;
        const lines = text.split('\n');
        const headers = lines[0].split(',').map(h => h.trim());

        // Validate headers
        if (!TEMPLATE_HEADERS.every(h => headers.includes(h))) {
          throw new Error('Invalid template format');
        }

        const clients = new Map();
        const matters: any[] = [];

        // Process data rows
        lines.slice(1).forEach((line) => {
          if (!line.trim()) return;

          const values = line.split(',').map(v => v.trim());
          const row = headers.reduce((obj, header, i) => {
            obj[header] = values[i];
            return obj;
          }, {} as any);

          // Create client object
          const clientData = {
            name: row['Client Name'],
            email: row['Client Email'],
            phone: row['Client Phone'],
            address: row['Client Address']
          };

          // Store unique clients
          if (!clients.has(clientData.email)) {
            clients.set(clientData.email, clientData);
          }

          // Create matter object
          if (row['Matter Title']) {
            matters.push({
              title: row['Matter Title'],
              description: row['Matter Description'],
              status: row['Matter Status'],
              billingType: row['Matter Billing Type'],
              startDate: row['Matter Start Date'],
              dueDate: row['Matter Due Date'],
              clientEmail: clientData.email // Reference to link with client later
            });
          }
        });

        resolve({
          clients: Array.from(clients.values()),
          matters
        });
      } catch (error) {
        reject(error);
      }
    };

    reader.onerror = () => reject(new Error('Failed to read file'));
    reader.readAsText(file);
  });
};