import React from 'react';
import { Mail } from 'lucide-react';
import { useUserSettings } from '../../hooks/useUserSettings';
import toast from 'react-hot-toast';

const EmailServiceSettings = () => {
  const { settings, updateSettings } = useUserSettings();

  const handleServiceChange = async (service: 'google' | 'outlook' | 'both') => {
    try {
      await updateSettings({ emailService: service });
      toast.success('Email service preference updated');
    } catch (error) {
      toast.error('Failed to update email service preference');
    }
  };

  return (
    <div className="bg-white rounded-xl shadow-sm p-6">
      <div className="flex items-center gap-3 mb-6">
        <div className="p-2 bg-blue-100 rounded-lg">
          <Mail className="w-5 h-5 text-blue-600" />
        </div>
        <div>
          <h2 className="text-xl font-semibold">Email Service</h2>
          <p className="text-sm text-gray-500">Choose your preferred email service for client communications</p>
        </div>
      </div>

      <div className="space-y-4">
        <div className="flex items-center gap-3">
          <input
            type="radio"
            id="google"
            name="emailService"
            checked={settings?.emailService === 'google'}
            onChange={() => handleServiceChange('google')}
            className="h-4 w-4 text-blue-600 focus:ring-blue-500"
          />
          <label htmlFor="google" className="text-gray-700">
            Google Mail only
          </label>
        </div>

        <div className="flex items-center gap-3">
          <input
            type="radio"
            id="outlook"
            name="emailService"
            checked={settings?.emailService === 'outlook'}
            onChange={() => handleServiceChange('outlook')}
            className="h-4 w-4 text-blue-600 focus:ring-blue-500"
          />
          <label htmlFor="outlook" className="text-gray-700">
            Microsoft Outlook only
          </label>
        </div>

        <div className="flex items-center gap-3">
          <input
            type="radio"
            id="both"
            name="emailService"
            checked={settings?.emailService === 'both'}
            onChange={() => handleServiceChange('both')}
            className="h-4 w-4 text-blue-600 focus:ring-blue-500"
          />
          <label htmlFor="both" className="text-gray-700">
            Show both options
          </label>
        </div>
      </div>
    </div>
  );
};

export default EmailServiceSettings;