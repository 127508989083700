import React, { useState } from 'react';
import { X, AlertTriangle } from 'lucide-react';
import type { Client } from '../../types';
import { useClients } from '../../hooks/useClients';
import { useDocumentTemplates } from '../../hooks/useDocumentTemplates';
import ClientDetailsView from './ClientDetailsView';
import toast from 'react-hot-toast';

interface ClientDetailsModalProps {
  client: Client;
  isOpen: boolean;
  onClose: () => void;
  onEdit: () => void;
}

const ClientDetailsModal: React.FC<ClientDetailsModalProps> = ({
  client,
  isOpen,
  onClose,
  onEdit
}) => {
  const { deleteClient } = useClients();
  const { templates } = useDocumentTemplates();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  
  if (!isOpen) return null;

  const clientDocuments = templates.filter(t => t.clientId === client.id);

  const handleDelete = async () => {
    try {
      await deleteClient(client.id);
      toast.success('Client deleted successfully');
      onClose();
    } catch (error) {
      toast.error('Failed to delete client');
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl shadow-lg w-full max-w-2xl">
        <div className="flex justify-between items-center p-6 border-b">
          <h2 className="text-xl font-semibold">{client.name}</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X className="w-5 h-5" />
          </button>
        </div>

        <div className="p-6">
          <ClientDetailsView 
            client={client}
            documents={clientDocuments}
            onViewDocument={(doc) => window.open(doc.fileUrl, '_blank')}
          />
        </div>

        <div className="flex justify-between gap-3 p-6 border-t">
          <button
            onClick={() => setShowDeleteConfirm(true)}
            className="px-4 py-2 text-red-600 hover:text-red-700 hover:bg-red-50 rounded-lg"
          >
            Delete Client
          </button>
          <div className="flex gap-3">
            <button
              onClick={onClose}
              className="px-4 py-2 text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200"
            >
              Close
            </button>
            <button
              onClick={onEdit}
              className="px-4 py-2 text-white bg-blue-600 rounded-lg hover:bg-blue-700"
            >
              Edit Client
            </button>
          </div>
        </div>
      </div>

      {showDeleteConfirm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-xl shadow-lg w-full max-w-md p-6">
            <div className="flex items-center gap-3 text-red-600 mb-4">
              <AlertTriangle className="w-6 h-6" />
              <h3 className="text-lg font-semibold">Delete Client</h3>
            </div>
            <p className="text-gray-600 mb-6">
              Are you sure you want to delete this client? This action cannot be undone.
              {clientDocuments.length > 0 && (
                <span className="block mt-2 text-red-600">
                  Warning: This client has {clientDocuments.length} associated document{clientDocuments.length > 1 ? 's' : ''}.
                </span>
              )}
            </p>
            <div className="flex justify-end gap-3">
              <button
                onClick={() => setShowDeleteConfirm(false)}
                className="px-4 py-2 text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200"
              >
                Cancel
              </button>
              <button
                onClick={handleDelete}
                className="px-4 py-2 text-white bg-red-600 rounded-lg hover:bg-red-700"
              >
                Delete Client
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClientDetailsModal;