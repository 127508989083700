import React from 'react';
import { useAuth } from '../contexts/AuthContext';
import SettingsSidebar from '../components/settings/SettingsSidebar';
import ProfileSettings from '../components/settings/ProfileSettings';
import RateSettings from '../components/settings/RateSettings';
import FixedFeeSettings from '../components/settings/FixedFeeSettings';
import SnippetsSettings from '../components/settings/SnippetsSettings';
import SubscriptionSettings from '../components/settings/SubscriptionSettings';
import InvoiceSettings from '../components/settings/InvoiceSettings';
import ImportExportSettings from '../components/settings/ImportExportSettings';
import EmailServiceSettings from '../components/settings/EmailServiceSettings';
import { Routes, Route, Navigate } from 'react-router-dom';

const Settings = () => {
  const { user } = useAuth();

  if (!user) return null;

  return (
    <div className="flex gap-6">
      <SettingsSidebar />
      <div className="flex-1">
        <Routes>
          <Route path="/" element={<Navigate to="profile" replace />} />
          <Route path="profile" element={<ProfileSettings />} />
          <Route path="rate" element={<RateSettings />} />
          <Route path="fixed-fees" element={<FixedFeeSettings />} />
          <Route path="invoice" element={<InvoiceSettings />} />
          <Route path="snippets" element={<SnippetsSettings />} />
          <Route path="subscription" element={<SubscriptionSettings />} />
          <Route path="import-export" element={<ImportExportSettings />} />
          <Route path="email" element={<EmailServiceSettings />} />
        </Routes>
      </div>
    </div>
  );
};

export default Settings;