import React, { useState, useEffect } from 'react';
import { useUserSettings } from '../../hooks/useUserSettings';
import toast from 'react-hot-toast';

const RateSettings = () => {
  const { settings, updateSettings, loading } = useUserSettings();
  const [rate, setRate] = useState('');
  const [currency, setCurrency] = useState('USD');

  useEffect(() => {
    if (settings) {
      setRate(settings.hourlyRate?.toString() || '0');
      setCurrency(settings.currency || 'USD');
    }
  }, [settings]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await updateSettings({
        hourlyRate: parseFloat(rate) || 0,
        currency
      });
      toast.success('Hourly rate updated successfully');
    } catch (error) {
      toast.error('Failed to update hourly rate');
    }
  };

  if (loading) {
    return <div className="bg-white rounded-xl shadow-sm p-6">Loading...</div>;
  }

  return (
    <div className="bg-white rounded-xl shadow-sm p-6">
      <h2 className="text-xl font-semibold mb-6">Hourly Rate Settings</h2>
      <form onSubmit={handleSubmit} className="space-y-6 max-w-2xl">
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Default Hourly Rate
            </label>
            <div className="relative">
              <span className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-500">
                $
              </span>
              <input
                type="number"
                min="0"
                step="0.01"
                className="w-full pl-8 pr-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500"
                value={rate}
                onChange={(e) => setRate(e.target.value)}
                placeholder="0.00"
              />
            </div>
            <p className="mt-1 text-sm text-gray-500">
              This rate will be used as the default for new time entries
            </p>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Currency
            </label>
            <select
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500"
              value={currency}
              onChange={(e) => setCurrency(e.target.value)}
            >
              <option value="USD">USD - US Dollar</option>
              <option value="EUR">EUR - Euro</option>
              <option value="GBP">GBP - British Pound</option>
              <option value="CAD">CAD - Canadian Dollar</option>
              <option value="AUD">AUD - Australian Dollar</option>
            </select>
          </div>
        </div>

        <div>
          <button
            type="submit"
            className="px-4 py-2 text-white bg-blue-600 rounded-lg hover:bg-blue-700"
          >
            Save Changes
          </button>
        </div>
      </form>
    </div>
  );
};

export default RateSettings;