import React, { useState } from 'react';
import { Plus } from 'lucide-react';
import { useNotes } from '../../hooks/useNotes';
import NoteForm from './notes/NoteForm';
import NotesList from './notes/NotesList';

const NotesPanel = () => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const { notes } = useNotes();

  return (
    <div className="bg-white rounded-xl shadow-sm">
      <div className="p-4 border-b flex justify-between items-center">
        <h2 className="text-lg font-semibold">Notes & Tasks</h2>
        <button
          onClick={() => setIsFormOpen(true)}
          className="px-3 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 flex items-center gap-2"
        >
          <Plus className="w-4 h-4" />
          New Note
        </button>
      </div>
      
      <NotesList notes={notes} />
      
      <NoteForm
        isOpen={isFormOpen}
        onClose={() => setIsFormOpen(false)}
      />
    </div>
  );
};

export default NotesPanel;