import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { 
  getFirestore, 
  enableIndexedDbPersistence,
  initializeFirestore,
  CACHE_SIZE_UNLIMITED,
  connectFirestoreEmulator
} from 'firebase/firestore';
import toast from 'react-hot-toast';

const firebaseConfig = {
  apiKey: "AIzaSyCoSkBkGSebnEIVHV_drnWfEZJeqr9-Fjw",
  authDomain: "boltlegalappv2.firebaseapp.com",
  projectId: "boltlegalappv2",
  storageBucket: "boltlegalappv2.firebasestorage.app",
  messagingSenderId: "64481521752",
  appId: "1:64481521752:web:f81608a932e51dfacd1a2d",
  measurementId: "G-CFP1ZR1ECE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Auth
export const auth = getAuth(app);

// Initialize Firestore with optimized settings
export const db = initializeFirestore(app, {
  cacheSizeBytes: CACHE_SIZE_UNLIMITED
});

// Enable offline persistence with error handling
const enablePersistence = async () => {
  try {
    await enableIndexedDbPersistence(db);
  } catch (err: any) {
    if (err.code === 'failed-precondition') {
      toast.error('Multiple tabs open. Offline mode only works in one tab at a time.');
    } else if (err.code === 'unimplemented') {
      toast.error('Your browser doesn\'t support offline mode.');
    }
  }
};

enablePersistence();

// Add network status monitoring
let isOnline = navigator.onLine;

function updateOnlineStatus() {
  const wasOnline = isOnline;
  isOnline = navigator.onLine;
  
  if (wasOnline && !isOnline) {
    toast.error('You are offline. Changes will be synced when connection is restored.');
  } else if (!wasOnline && isOnline) {
    toast.success('Back online! Syncing changes...');
  }
}

window.addEventListener('online', updateOnlineStatus);
window.addEventListener('offline', updateOnlineStatus);

export { isOnline };