import { useState } from 'react';

export type SortDirection = 'asc' | 'desc';
export type SortConfig = {
  key: string;
  direction: SortDirection;
};

export const useSorting = (initialKey: string = 'date', initialDirection: SortDirection = 'desc') => {
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    key: initialKey,
    direction: initialDirection,
  });

  const toggleSort = (key: string) => {
    setSortConfig((currentConfig) => ({
      key,
      direction:
        currentConfig.key === key && currentConfig.direction === 'asc'
          ? 'desc'
          : 'asc',
    }));
  };

  return { sortConfig, toggleSort };
};