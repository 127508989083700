import { format } from 'date-fns';
import type { TimeEntry } from '../types';
import { getDocumentService } from './documentUtils';

interface ExportableTimeEntry {
  date: string;
  description: string;
  hours: string;
  rate: string;
  amount: string;
  matter: string;
  client: string;
  paymentStatus: string;
  paymentDate?: string;
  paymentMethod?: string;
  paymentReference?: string;
}

export const exportTimeEntries = (
  entries: TimeEntry[],
  type: 'billed' | 'paid',
  getMatterTitle: (id: string) => string,
  getClientName: (id: string) => string,
  openWith?: 'google' | 'microsoft'
) => {
  // Convert entries to exportable format
  const exportableEntries: ExportableTimeEntry[] = entries
    .filter(entry => type === 'billed' ? (entry.billed && !entry.paid) : entry.paid)
    .map(entry => ({
      date: format(new Date(entry.date), 'yyyy-MM-dd'),
      description: entry.description,
      hours: entry.billingType === 'fixed' ? 'N/A' : entry.hours?.toString() || '0',
      rate: entry.billingType === 'fixed' ? 'N/A' : `$${entry.rate}`,
      amount: entry.billingType === 'fixed' 
        ? `$${entry.fixedFeeAmount}` 
        : `$${(entry.hours * entry.rate).toFixed(2)}`,
      matter: getMatterTitle(entry.matterId),
      client: getClientName(entry.clientId),
      paymentStatus: type === 'paid' ? 'Paid' : 'Billed',
      paymentDate: entry.paymentDate ? format(new Date(entry.paymentDate), 'yyyy-MM-dd') : '',
      paymentMethod: entry.paymentMethod || '',
      paymentReference: entry.paymentReference || ''
    }));

  // Create CSV content
  const headers = [
    'Date',
    'Description',
    'Hours',
    'Rate',
    'Amount',
    'Matter',
    'Client',
    'Status',
    'Payment Date',
    'Payment Method',
    'Payment Reference'
  ];

  const csvContent = [
    headers.join(','),
    ...exportableEntries.map(entry => [
      entry.date,
      `"${entry.description}"`,
      entry.hours,
      entry.rate,
      entry.amount,
      `"${entry.matter}"`,
      `"${entry.client}"`,
      entry.paymentStatus,
      entry.paymentDate,
      entry.paymentMethod,
      entry.paymentReference
    ].join(','))
  ].join('\n');

  if (openWith) {
    // Open with specific service
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    
    if (openWith === 'google') {
      window.open(`https://docs.google.com/spreadsheets/d/create?usp=upload&source=csv&data=${encodeURIComponent(csvContent)}`);
    } else {
      window.open(`https://excel.office.com/import?source=csv&data=${encodeURIComponent(csvContent)}`);
    }
    
    URL.revokeObjectURL(url);
  } else {
    // Download locally
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', `time-entries-${type}-${format(new Date(), 'yyyy-MM-dd')}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }
};