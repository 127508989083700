import React, { useState } from 'react';
import { Download, Loader } from 'lucide-react';
import { useClients } from '../../hooks/useClients';
import { useMatters } from '../../hooks/useMatters';
import { useTimeEntries } from '../../hooks/useTimeEntries';
import { useDocumentTemplates } from '../../hooks/useDocumentTemplates';
import { exportClients, exportMatters, exportTimeEntries, exportDocuments } from '../../utils/dataExport';
import toast from 'react-hot-toast';

const DataExportSection = () => {
  const { clients } = useClients();
  const { matters } = useMatters();
  const { timeEntries } = useTimeEntries();
  const { templates } = useDocumentTemplates();
  const [exporting, setExporting] = useState('');

  const handleExport = async (type: string) => {
    setExporting(type);
    try {
      switch (type) {
        case 'clients':
          exportClients(clients);
          break;
        case 'matters':
          exportMatters(matters);
          break;
        case 'time-entries':
          exportTimeEntries(timeEntries);
          break;
        case 'documents':
          exportDocuments(templates);
          break;
      }
      toast.success(`${type} exported successfully`);
    } catch (error) {
      toast.error(`Failed to export ${type}`);
      console.error('Export error:', error);
    } finally {
      setExporting('');
    }
  };

  const exportOptions = [
    { type: 'clients', label: 'Clients', count: clients.length },
    { type: 'matters', label: 'Matters', count: matters.length },
    { type: 'time-entries', label: 'Time Entries', count: timeEntries.length },
    { type: 'documents', label: 'Documents', count: templates.length }
  ];

  return (
    <div className="space-y-6">
      <h2 className="text-lg font-semibold">Export Data</h2>
      <p className="text-sm text-gray-600">
        Export your data in CSV format for backup or analysis
      </p>

      <div className="grid grid-cols-2 gap-4">
        {exportOptions.map(({ type, label, count }) => (
          <div key={type} className="p-4 border rounded-lg">
            <div className="flex justify-between items-center mb-2">
              <h3 className="font-medium">{label}</h3>
              <span className="text-sm text-gray-500">{count} records</span>
            </div>
            <button
              onClick={() => handleExport(type)}
              disabled={exporting === type || count === 0}
              className="w-full px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 flex items-center justify-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {exporting === type ? (
                <Loader className="w-4 h-4 animate-spin" />
              ) : (
                <Download className="w-4 h-4" />
              )}
              {exporting === type ? 'Exporting...' : `Export ${label}`}
            </button>
          </div>
        ))}
      </div>

      <div className="mt-4 text-sm text-gray-500">
        <p className="font-medium mb-2">Export Format Details:</p>
        <ul className="list-disc ml-4 space-y-1">
          <li>All data is exported in CSV format</li>
          <li>Files can be opened in Excel or other spreadsheet software</li>
          <li>Dates are formatted as YYYY-MM-DD</li>
          <li>Text fields containing commas are properly quoted</li>
        </ul>
      </div>
    </div>
  );
};

export default DataExportSection;