import React from 'react';
import { format } from 'date-fns';
import { X, Printer, Download } from 'lucide-react';
import { useTimeEntries } from '../../hooks/useTimeEntries';
import { useUserSettings } from '../../hooks/useUserSettings';
import type { TimeEntry, Matter, Client, UserSettings } from '../../types';
import toast from 'react-hot-toast';

interface InvoicePreviewProps {
  isOpen: boolean;
  onClose: () => void;
  timeEntries: TimeEntry[];
  groupedEntries: Record<string, TimeEntry[]>;
  settings: UserSettings | null;
  matters: Matter[];
  clients: Client[];
}

const InvoicePreview: React.FC<InvoicePreviewProps> = ({
  isOpen,
  onClose,
  timeEntries,
  groupedEntries,
  settings,
  matters,
  clients
}) => {
  const { updateTimeEntry } = useTimeEntries();

  if (!isOpen) return null;

  const getMatterTitle = (matterId: string) => {
    return matters.find(m => m.id === matterId)?.title || 'Unknown Matter';
  };

  const getClientInfo = (clientId: string) => {
    return clients.find(c => c.id === clientId);
  };

  const calculateSubtotal = (entries: TimeEntry[]) => {
    return entries.reduce((total, entry) => {
      if (entry.billingType === 'fixed') {
        return total + (entry.fixedFeeAmount || 0);
      }
      return total + (entry.hours * entry.rate);
    }, 0);
  };

  const total = calculateSubtotal(timeEntries);
  const taxAmount = settings?.invoiceSettings?.taxRate ? 
    (total * settings.invoiceSettings.taxRate / 100) : 0;
  const grandTotal = total + taxAmount;

  const invoiceNumber = `${settings?.invoiceSettings?.invoicePrefix || 'INV-'}${Date.now().toString().slice(-6)}`;
  const invoiceDate = format(new Date(), 'MMMM d, yyyy');

  const handlePrint = async () => {
    try {
      await Promise.all(
        timeEntries.map(entry => updateTimeEntry(entry.id, { billed: true }))
      );
      toast.success('Time entries marked as billed');
      window.print();
      onClose();
    } catch (error) {
      toast.error('Failed to update time entries');
    }
  };

  const handleDownload = async () => {
    try {
      await Promise.all(
        timeEntries.map(entry => updateTimeEntry(entry.id, { billed: true }))
      );
      toast.success('Time entries marked as billed');
      window.print();
      onClose();
    } catch (error) {
      toast.error('Failed to update time entries');
    }
  };

  // Get the first time entry to determine client info
  const firstEntry = timeEntries[0];
  const clientInfo = firstEntry ? getClientInfo(firstEntry.clientId) : null;

  // Get attorney signature based on settings
  const getAttorneySignature = () => {
    if (!settings) return '';
    
    if (settings.invoiceSettings?.showFullName && settings.firstName && settings.lastName) {
      return `${settings.firstName} ${settings.lastName}`;
    }
    
    if (settings.invoiceSettings?.showInitials && settings.initials) {
      return settings.initials;
    }
    
    return '';
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl shadow-lg w-full max-w-4xl max-h-[90vh] flex flex-col">
        <div className="flex justify-between items-center p-4 border-b print:hidden">
          <h2 className="text-xl font-semibold">Invoice Preview</h2>
          <div className="flex items-center gap-2">
            <button
              onClick={handlePrint}
              className="px-4 py-2 text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200 flex items-center gap-2"
            >
              <Printer className="w-4 h-4" />
              Print & Mark as Billed
            </button>
            <button
              onClick={handleDownload}
              className="px-4 py-2 text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200 flex items-center gap-2"
            >
              <Download className="w-4 h-4" />
              Download & Mark as Billed
            </button>
            <button
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700"
            >
              <X className="w-5 h-5" />
            </button>
          </div>
        </div>

        <div className="p-8 overflow-y-auto">
          {/* Invoice Header */}
          <div className="mb-8 flex justify-between">
            <div>
              <h1 className="text-2xl font-bold text-gray-900 mb-2">INVOICE</h1>
              <p className="text-gray-600">{invoiceNumber}</p>
              <p className="text-gray-600">Date: {invoiceDate}</p>
            </div>
            <div className="text-right">
              <h2 className="font-bold text-xl mb-2">{settings?.company || 'Your Company Name'}</h2>
              <p className="text-gray-600 whitespace-pre-line">{settings?.address || 'Your Address'}</p>
              <p className="text-gray-600">{settings?.phone || 'Your Phone'}</p>
              {getAttorneySignature() && (
                <p className="text-gray-600 mt-2">
                  Billing Attorney: {getAttorneySignature()}
                </p>
              )}
            </div>
          </div>

          {/* Client Information */}
          <div className="mb-8">
            <h3 className="font-bold text-gray-700 mb-2">Bill To:</h3>
            <p className="font-medium">{clientInfo?.name}</p>
            <p className="text-gray-600">{clientInfo?.address}</p>
            <p className="text-gray-600">{clientInfo?.email}</p>
            <p className="text-gray-600">{clientInfo?.phone}</p>
          </div>

          {/* Time Entries Table */}
          <table className="w-full mb-8">
            <thead>
              <tr className="border-b">
                <th className="text-left py-2">Description</th>
                <th className="text-left py-2">Matter</th>
                <th className="text-right py-2">Hours/Fee</th>
                <th className="text-right py-2">Rate</th>
                <th className="text-right py-2">Amount</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(groupedEntries).map(([matterId, entries]) => (
                <React.Fragment key={matterId}>
                  {entries.map((entry, index) => (
                    <tr key={entry.id} className="border-b">
                      <td className="py-2">{entry.description}</td>
                      <td className="py-2">{getMatterTitle(entry.matterId)}</td>
                      <td className="text-right py-2">
                        {entry.billingType === 'fixed' ? 
                          entry.fixedFeeTitle : 
                          `${entry.hours} hrs`}
                      </td>
                      <td className="text-right py-2">
                        {entry.billingType === 'fixed' ? 
                          '-' : 
                          `$${entry.rate}/hr`}
                      </td>
                      <td className="text-right py-2">
                        ${entry.billingType === 'fixed' ? 
                          entry.fixedFeeAmount?.toFixed(2) : 
                          (entry.hours * entry.rate).toFixed(2)}
                      </td>
                    </tr>
                  ))}
                </React.Fragment>
              ))}
            </tbody>
          </table>

          {/* Totals */}
          <div className="w-64 ml-auto">
            <div className="flex justify-between py-2">
              <span>Subtotal:</span>
              <span>${total.toFixed(2)}</span>
            </div>
            {settings?.invoiceSettings?.taxRate && (
              <div className="flex justify-between py-2 border-b">
                <span>Tax ({settings.invoiceSettings.taxRate}%):</span>
                <span>${taxAmount.toFixed(2)}</span>
              </div>
            )}
            <div className="flex justify-between py-2 font-bold text-lg">
              <span>Total:</span>
              <span>${grandTotal.toFixed(2)}</span>
            </div>
          </div>

          {/* Footer */}
          <div className="mt-8 pt-8 border-t">
            <div className="mb-4">
              <h4 className="font-bold mb-2">Payment Terms</h4>
              <p className="text-gray-600">{settings?.invoiceSettings?.paymentTerms || 'Net 30'}</p>
            </div>
            {settings?.invoiceSettings?.bankDetails && (
              <div className="mb-4">
                <h4 className="font-bold mb-2">Payment Details</h4>
                <p className="text-gray-600 whitespace-pre-line">
                  {settings.invoiceSettings.bankDetails}
                </p>
              </div>
            )}
            {settings?.invoiceSettings?.invoiceNotes && (
              <div className="mb-4">
                <h4 className="font-bold mb-2">Notes</h4>
                <p className="text-gray-600">{settings.invoiceSettings.invoiceNotes}</p>
              </div>
            )}
            {settings?.invoiceSettings?.footerText && (
              <div className="text-center text-gray-500 text-sm mt-8">
                {settings.invoiceSettings.footerText}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoicePreview;