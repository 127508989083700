import React, { useState } from 'react';
import { format } from 'date-fns';
import { Circle, FileText, AlertCircle, Edit2, Trash2, X } from 'lucide-react';
import { useNotes } from '../../../hooks/useNotes';
import { useClients } from '../../../hooks/useClients';
import { useMatters } from '../../../hooks/useMatters';
import type { Note } from '../../../types';
import toast from 'react-hot-toast';

interface NotesListProps {
  notes: Note[];
}

const NotesList: React.FC<NotesListProps> = ({ notes }) => {
  const { updateNote, deleteNote } = useNotes();
  const { clients } = useClients();
  const { matters } = useMatters();
  const [editingNote, setEditingNote] = useState<Note | null>(null);
  const [editForm, setEditForm] = useState({
    title: '',
    content: '',
    dueDate: ''
  });

  const activeNotes = notes.filter(note => !note.completed);

  const handleComplete = async (note: Note) => {
    try {
      await updateNote(note.id, { completed: true });
      toast.success('Task marked as complete');
    } catch (error) {
      toast.error('Failed to update task');
    }
  };

  const handleEdit = (note: Note) => {
    setEditingNote(note);
    setEditForm({
      title: note.title,
      content: note.content,
      dueDate: note.dueDate || ''
    });
  };

  const handleSaveEdit = async (note: Note) => {
    try {
      await updateNote(note.id, editForm);
      toast.success('Note updated successfully');
      setEditingNote(null);
    } catch (error) {
      toast.error('Failed to update note');
    }
  };

  const handleDelete = async (id: string) => {
    try {
      await deleteNote(id);
      toast.success('Note deleted successfully');
    } catch (error) {
      toast.error('Failed to delete note');
    }
  };

  const getClientName = (clientId?: string) => {
    if (!clientId) return null;
    return clients.find(c => c.id === clientId)?.name;
  };

  const getMatterTitle = (matterId?: string) => {
    if (!matterId) return null;
    return matters.find(m => m.id === matterId)?.title;
  };

  return (
    <div className="divide-y">
      {activeNotes.map(note => (
        <div key={note.id} className="p-4 hover:bg-gray-50">
          {editingNote?.id === note.id ? (
            <div className="space-y-3">
              <input
                type="text"
                className="w-full px-3 py-2 border border-gray-300 rounded-lg"
                value={editForm.title}
                onChange={(e) => setEditForm(prev => ({ ...prev, title: e.target.value }))}
              />
              <textarea
                rows={3}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg"
                value={editForm.content}
                onChange={(e) => setEditForm(prev => ({ ...prev, content: e.target.value }))}
              />
              {note.type === 'task' && (
                <input
                  type="date"
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg"
                  value={editForm.dueDate}
                  onChange={(e) => setEditForm(prev => ({ ...prev, dueDate: e.target.value }))}
                />
              )}
              <div className="flex justify-end gap-2">
                <button
                  onClick={() => setEditingNote(null)}
                  className="px-3 py-1 text-gray-600 hover:text-gray-800"
                >
                  Cancel
                </button>
                <button
                  onClick={() => handleSaveEdit(note)}
                  className="px-3 py-1 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                >
                  Save
                </button>
              </div>
            </div>
          ) : (
            <div className="flex items-start gap-3">
              {note.type === 'task' ? (
                <button
                  onClick={() => handleComplete(note)}
                  className="mt-1"
                >
                  <Circle className="w-5 h-5 text-gray-400 hover:text-green-600" />
                </button>
              ) : (
                <FileText className="w-5 h-5 text-blue-600 mt-1" />
              )}
              <div className="flex-1">
                <div className="flex justify-between">
                  <h3 className="font-medium text-gray-900">{note.title}</h3>
                  <div className="flex gap-2">
                    <button
                      onClick={() => handleEdit(note)}
                      className="text-gray-400 hover:text-gray-600"
                    >
                      <Edit2 className="w-4 h-4" />
                    </button>
                    <button
                      onClick={() => handleDelete(note.id)}
                      className="text-gray-400 hover:text-gray-600"
                    >
                      <Trash2 className="w-4 h-4" />
                    </button>
                  </div>
                </div>
                <p className="text-gray-600 mt-1">{note.content}</p>
                {(note.clientId || note.matterId) && (
                  <div className="flex gap-2 mt-2">
                    {note.clientId && (
                      <span className="text-xs bg-blue-100 text-blue-800 px-2 py-0.5 rounded-full">
                        {getClientName(note.clientId)}
                      </span>
                    )}
                    {note.matterId && (
                      <span className="text-xs bg-purple-100 text-purple-800 px-2 py-0.5 rounded-full">
                        {getMatterTitle(note.matterId)}
                      </span>
                    )}
                  </div>
                )}
                {note.type === 'task' && note.dueDate && (
                  <div className="mt-2 flex items-center gap-1 text-xs">
                    <AlertCircle className="w-4 h-4 text-orange-500" />
                    <span className="text-orange-600">
                      Due: {format(new Date(note.dueDate), 'MMM d, yyyy')}
                    </span>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default NotesList;