import { useState } from 'react';

export interface TimeEntryFilters {
  clientId: string;
  matterId: string;
  dateFrom: string;
  dateTo: string;
}

export const useFilters = (initialFilters: TimeEntryFilters) => {
  const [filters, setFilters] = useState<TimeEntryFilters>(initialFilters);

  const updateFilter = (key: keyof TimeEntryFilters, value: string) => {
    setFilters(prev => ({
      ...prev,
      [key]: value,
      ...(key === 'clientId' && { matterId: '' }) // Reset matter when client changes
    }));
  };

  const resetFilters = () => {
    setFilters(initialFilters);
  };

  return { filters, updateFilter, resetFilters };
};