import React, { useState } from 'react';
import { Clock, Users } from 'lucide-react';
import DashboardHeader from '../components/dashboard/DashboardHeader';
import StatCard from '../components/dashboard/StatCard';
import NotesPanel from '../components/dashboard/NotesPanel';
import RecentTimeEntries from '../components/dashboard/RecentTimeEntries';
import RecentDocuments from '../components/dashboard/RecentDocuments';
import RecentClientFiles from '../components/dashboard/RecentClientFiles';
import NewMatterForm from '../components/matters/NewMatterForm';
import { useMatters } from '../hooks/useMatters';
import { useClients } from '../hooks/useClients';

const Dashboard = () => {
  const [isNewMatterOpen, setIsNewMatterOpen] = useState(false);
  const { matters } = useMatters();
  const { clients } = useClients();

  const stats = [
    {
      icon: Clock,
      label: 'Active Matters',
      value: matters.filter(m => m.status === 'active').length,
      color: 'bg-blue-100'
    },
    {
      icon: Users,
      label: 'Total Clients',
      value: clients.length,
      color: 'bg-orange-100'
    }
  ];

  return (
    <div className="space-y-6">
      <DashboardHeader onNewMatter={() => setIsNewMatterOpen(true)} />

      <div className="grid grid-cols-2 gap-6">
        {stats.map((stat, index) => (
          <StatCard key={index} {...stat} />
        ))}
      </div>

      <NotesPanel />

      <div className="grid grid-cols-2 gap-6">
        <RecentTimeEntries />
        <RecentDocuments />
      </div>

      <RecentClientFiles />

      <NewMatterForm
        isOpen={isNewMatterOpen}
        onClose={() => setIsNewMatterOpen(false)}
        onSuccess={() => setIsNewMatterOpen(false)}
      />
    </div>
  );
};

export default Dashboard;