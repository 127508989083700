import React, { useState } from 'react';
import { Mail, ChevronDown } from 'lucide-react';
import { useUserSettings } from '../../hooks/useUserSettings';
import { getEmailProviderUrl } from '../../utils/emailUtils';

interface EmailButtonProps {
  email: string;
}

const EmailButton: React.FC<EmailButtonProps> = ({ email }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const { settings } = useUserSettings();

  const handleEmailClient = (provider: 'google' | 'outlook') => {
    window.open(getEmailProviderUrl(email, provider), '_blank');
    setShowDropdown(false);
  };

  // If no preference is set, default to showing both options
  const emailService = settings?.emailService || 'both';

  // If only one service is selected, open it directly
  if (emailService === 'google') {
    return (
      <button
        onClick={() => handleEmailClient('google')}
        className="flex items-center gap-1 text-blue-600 hover:text-blue-700"
      >
        <Mail className="w-4 h-4" />
        <span>Gmail</span>
      </button>
    );
  }

  if (emailService === 'outlook') {
    return (
      <button
        onClick={() => handleEmailClient('outlook')}
        className="flex items-center gap-1 text-blue-600 hover:text-blue-700"
      >
        <Mail className="w-4 h-4" />
        <span>Outlook</span>
      </button>
    );
  }

  // Show dropdown for both options
  return (
    <div className="relative">
      <button
        onClick={() => setShowDropdown(!showDropdown)}
        className="flex items-center gap-1 text-blue-600 hover:text-blue-700"
      >
        <Mail className="w-4 h-4" />
        <span>Email</span>
        <ChevronDown className="w-4 h-4" />
      </button>

      {showDropdown && (
        <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg border py-1 z-10">
          <button
            onClick={() => handleEmailClient('google')}
            className="w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
          >
            Open in Gmail
          </button>
          <button
            onClick={() => handleEmailClient('outlook')}
            className="w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
          >
            Open in Outlook
          </button>
        </div>
      )}
    </div>
  );
};

export default EmailButton;