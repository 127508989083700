import React from 'react';
import { FileText, ExternalLink } from 'lucide-react';
import { useClients } from '../../hooks/useClients';

const RecentClientFiles = () => {
  const { clients } = useClients();

  // Filter clients with fileUrl and sort by most recently updated
  const recentFiles = clients
    .filter(client => client.fileUrl)
    .sort((a, b) => {
      const dateA = a.updatedAt?.toDate() || new Date(0);
      const dateB = b.updatedAt?.toDate() || new Date(0);
      return dateB.getTime() - dateA.getTime();
    })
    .slice(0, 5);

  if (recentFiles.length === 0) {
    return null;
  }

  return (
    <div className="bg-white rounded-xl shadow-sm">
      <div className="p-6">
        <div className="flex items-center gap-2 mb-4">
          <FileText className="w-5 h-5 text-blue-600" />
          <h2 className="text-lg font-semibold">Recent Client Files</h2>
        </div>
        <div className="space-y-4">
          {recentFiles.map((client) => (
            <div key={client.id} className="flex items-start gap-4 p-3 bg-gray-50 rounded-lg">
              <div className="flex-1">
                <div className="flex justify-between items-start">
                  <div>
                    <p className="font-medium text-gray-900">{client.name}</p>
                    <p className="text-sm text-gray-600 mt-1">{client.email}</p>
                  </div>
                  <a
                    href={client.fileUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 hover:text-blue-700 flex items-center gap-1"
                  >
                    <span className="text-sm">Open File</span>
                    <ExternalLink className="w-4 h-4" />
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RecentClientFiles;