import React from 'react';
import { NavLink } from 'react-router-dom';
import { User, DollarSign, CreditCard, FileText, Tag, Receipt, Upload, Mail } from 'lucide-react';

const SettingsSidebar = () => {
  const navItems = [
    { to: 'profile', icon: User, label: 'Profile' },
    { to: 'rate', icon: DollarSign, label: 'Hourly Rate' },
    { to: 'fixed-fees', icon: Tag, label: 'Fixed Fees' },
    { to: 'invoice', icon: Receipt, label: 'Invoice' },
    { to: 'snippets', icon: FileText, label: 'Snippets' },
    { to: 'email', icon: Mail, label: 'Email Service' },
    { to: 'import-export', icon: Upload, label: 'Import/Export' },
    { to: 'subscription', icon: CreditCard, label: 'Subscription' },
  ];

  return (
    <div className="w-64 bg-white rounded-xl shadow-sm p-4">
      <h2 className="text-lg font-semibold px-3 mb-4">Settings</h2>
      <nav className="space-y-1">
        {navItems.map((item) => (
          <NavLink
            key={item.to}
            to={item.to}
            className={({ isActive }) =>
              `flex items-center gap-3 px-3 py-2 rounded-lg transition-colors ${
                isActive
                  ? 'bg-blue-50 text-blue-600'
                  : 'text-gray-700 hover:bg-gray-50'
              }`
            }
          >
            <item.icon className="w-5 h-5" />
            <span>{item.label}</span>
          </NavLink>
        ))}
      </nav>
    </div>
  );
};

export default SettingsSidebar;