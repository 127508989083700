import React, { useState } from 'react';
import { Download, Calendar } from 'lucide-react';
import { exportTimeEntries } from '../../utils/timeEntriesExport';
import type { TimeEntry } from '../../types';

interface TimeEntriesExportProps {
  entries: TimeEntry[];
  type: 'billed' | 'paid';
  getMatterTitle: (id: string) => string;
  getClientName: (id: string) => string;
}

const TimeEntriesExport: React.FC<TimeEntriesExportProps> = ({
  entries,
  type,
  getMatterTitle,
  getClientName
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [dateRange, setDateRange] = useState({
    from: '',
    to: ''
  });

  const handleExport = () => {
    const filteredEntries = entries.filter(entry => {
      const entryDate = new Date(entry.date);
      const fromDate = dateRange.from ? new Date(dateRange.from) : new Date(0);
      const toDate = dateRange.to ? new Date(dateRange.to) : new Date();
      return entryDate >= fromDate && entryDate <= toDate;
    });

    exportTimeEntries(filteredEntries, type, getMatterTitle, getClientName);
    setShowOptions(false);
  };

  return (
    <div className="relative">
      <button
        onClick={() => setShowOptions(!showOptions)}
        className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 flex items-center gap-2"
      >
        <Download className="w-4 h-4" />
        Export {type === 'paid' ? 'Paid' : 'Billed'} Entries
      </button>

      {showOptions && (
        <div className="absolute right-0 mt-2 w-80 bg-white rounded-lg shadow-lg border p-4 z-10">
          <div className="mb-4">
            <h3 className="text-sm font-medium text-gray-700 mb-2">Date Range</h3>
            <div className="space-y-2">
              <div>
                <label className="block text-xs text-gray-500 mb-1">From</label>
                <input
                  type="date"
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg text-sm"
                  value={dateRange.from}
                  onChange={(e) => setDateRange(prev => ({ ...prev, from: e.target.value }))}
                />
              </div>
              <div>
                <label className="block text-xs text-gray-500 mb-1">To</label>
                <input
                  type="date"
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg text-sm"
                  value={dateRange.to}
                  onChange={(e) => setDateRange(prev => ({ ...prev, to: e.target.value }))}
                />
              </div>
            </div>
          </div>

          <button
            onClick={handleExport}
            className="w-full px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 flex items-center justify-center gap-2"
          >
            <Download className="w-4 h-4" />
            Download CSV
          </button>
        </div>
      )}
    </div>
  );
};

export default TimeEntriesExport;